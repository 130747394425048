import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/river/logo.svg';

import img1 from '../../images/projects/river/img1.jpg';
import img12x from '../../images/projects/river/img1@2x.jpg';
import img2 from '../../images/projects/river/img2.jpg';
import img22x from '../../images/projects/river/img2@2x.jpg';
import img3 from '../../images/projects/river/img3.jpg';
import img32x from '../../images/projects/river/img3@2x.jpg';
import img4 from '../../images/projects/river/img4.jpg';
import img42x from '../../images/projects/river/img4@2x.jpg';
import img5 from '../../images/projects/river/img5.jpg';
import img52x from '../../images/projects/river/img5@2x.jpg';

import small1 from '../../images/projects/river/small1.jpg';
import small12x from '../../images/projects/river/small1@2x.jpg';
import small2 from '../../images/projects/river/small2.jpg';
import small22x from '../../images/projects/river/small2@2x.jpg';
import small3 from '../../images/projects/river/small3.jpg';
import small32x from '../../images/projects/river/small3@2x.jpg';

const title = 'Corporate Design für River nordic design ';
const text =
	'Das Corporate Design des Einzelhandels „RIVER nordic design“ wurde anlässlich seiner Geschäftsgründung in Saarbrücken entworfen. Es entstand ein Konzept, das auf zahlreichen Großflächenplakaten, Social-Media-Anzeigen und Bussen für die Eröffnung der Filiale warb. Darüber hinaus wurden deren Fassade und Fenster beschriftet sowie Gutscheine, Aufkleber und Postkarten für den Laden gedruckt. Inneneinrichtung und Gestaltung überzeugen dabei insbesondere durch eines: das schlichte nordische Design.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="" className="river-logo" />
				</ProjectItem>
				<ProjectItem>
					<Image src={img1} src2={img12x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} src2={img22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img3} src2={img32x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={small1} src2={small12x} />
					<Image src={small2} src2={small22x} />
					<Image src={small3} src2={small32x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img4} src2={img42x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img5} src2={img52x} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
